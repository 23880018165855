import "./archive.scss"
import anime from 'animejs/lib/anime.es.js'
import { windowWidth, windowHeight } from "@shared/listener";
import Component from '../../shared/component'


//import "@assets/icon-close.png"


// Constants

const CLASS = 'm-Archive'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class Archive extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Archive(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    //console.log('init Archive')

    let items = this.root.querySelectorAll('.m-Archive-element');
    let lightbox = this.root.querySelector('.m-Archive-lightbox');

    let w;
    let h;
    let newW;
    let newH;
    let top = 0;
    let left = 0;

    items.forEach(item => {
      
      item.addEventListener('click', () => {
        
        lightbox.classList.remove('hidden');
        document.querySelector('body').classList.add('lockScroll')
        const image = item.querySelector('.m-Archive-image')
        
        w = image.offsetWidth;
        h = image.offsetHeight;
        newW;
        newH;
        top = image.getBoundingClientRect().top;
        left = image.getBoundingClientRect().left;


        const info = item.querySelector('.m-Archive-info')

        var clnInfo = info.cloneNode(true);
        lightbox.appendChild(clnInfo);
        clnInfo.style.padding = "1rem";
        clnInfo.classList.add('m-Archive-lightbox-info');
        
        let infoW = clnInfo.offsetWidth;
        let infoH = clnInfo.offsetHeight;
        let infoTop = 0;
        let infoLeft = 0;

        var ratio = w / h;
        var pratio = windowWidth / windowHeight;
        if (ratio < pratio) {
          //height 100%
          newH = windowHeight;
          newW = newH * ratio;
          if(newW < windowWidth - infoW) {
            //place on right
          } else {
            newW = newW - (newW - (windowWidth - infoW)) //reduce width
            newH = newW * (h / w); //recalculate H
            //place on right
          }
          infoTop = newH - infoH;
          infoLeft = newW;
        }
        else {
          //width: 100%
          newW = windowWidth;
          newH = newW * (h / w);

          if(newH < windowHeight - infoH) {
            //place on bottom
          } else {
            newH = newH - (newH - (windowHeight - infoH)) //reduce height
            newW = newH * ratio; //recalculate W
            //place on bottom
          }
          infoTop = newH;
          infoLeft = 0;
        }
  
        var cln = image.cloneNode(true);
        cln.classList.add('m-Archive-lightbox-image');
        cln.style.top = top + "px";
        cln.style.left = left + "px";
        cln.style.width = w + "px";
        lightbox.appendChild(cln);

        clnInfo.style.top = infoTop + "px";
        clnInfo.style.left = infoLeft + "px";

        const animation = anime({
          targets: cln,
          width: [w, newW],
          height: [h, newH],
          top: 0,
          left: 0,
          duration: 400,
          easing: 'easeInOutQuint',
        })

        let infoElements = clnInfo.querySelectorAll('.m-Archive-title,.m-Archive-description,.m-Button')
        const animationInfo = anime({
          targets: infoElements,
          opacity: [0, 1],
          translateY: [-20, 0],
          delay: anime.stagger(200, {start: 400}),
          duration: 200,
          easing: 'easeOutQuad',
        })
      })
    })

    this.root.querySelector('.m-Archive-lightbox-icon').addEventListener('click', () => {
      lightbox.classList.add('hidden');
      document.querySelector('body').classList.remove('lockScroll')
      let cln = lightbox.querySelector('.m-Archive-lightbox-image');
      let clnInfo = lightbox.querySelector('.m-Archive-lightbox-info');
      const animation = anime({
        targets: cln,
        width: [newW, w],
        height: [newH, h],
        top: [0, top],
        left: [0, left],
        duration: 400,
        easing: 'easeInOutQuint',
        complete: function(anim) {
          lightbox.removeChild(cln)
        }
      })

      let infoElements = clnInfo.querySelectorAll('.m-Archive-title,.m-Archive-description,.m-Button')
      const animationInfo = anime({
        targets: infoElements,
        opacity: [1, 0],
        translateY: [0, 20],
        delay: anime.stagger(200, {direction: 'reverse'}),
        duration: 200,
        easing: 'easeInQuad',
        complete: function(anim) {
          lightbox.removeChild(clnInfo)
        }
      })
    })

  }
}

