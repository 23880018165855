import {mousePosition, scrollPosition} from '@shared/listener'
import { clamp, normalize } from "@shared/math";
import scheduler, { loop, render } from "@downpourdigital/scheduler";

scheduler.start();

//let headline = document.querySelector('.m-Header');

export let loopItems = [];

export function Item(id) {
  this.id = id
}

let loopItem = new Item('start')
loopItem.loop = function() {
  /*console.log(this.id);*/
}

loopItems.push(loopItem);

loop(() => [
  render((delta, time) => {
    //const y = Math.sin(mousePosition.x / 100) * 0.25;

    loopItems.forEach(item => {
      item.loop();
    })

    const y = mousePosition.y
    const x = mousePosition.x
    //headline.style.transform = `translate3d(${x}px, ${y}px, 0)`;
  })
]);