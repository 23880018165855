import "./puzzle.scss"
import Component from '../../shared/component'
import { map} from "@shared/math";
import anime from 'animejs/lib/anime.es.js'


import "@assets/puzzle-icon-reset.png"
import "@assets/puzzle-icon-arrow.png"

import "@assets/puzzle/1AB060088E308FF5.png"
import "@assets/puzzle/1AB060088E30900B.png"
import "@assets/puzzle/1AB060088E30900C.png"
import "@assets/puzzle/1AB060088E30900D.png"
import "@assets/puzzle/1AB060088E30900F.png"
import "@assets/puzzle/1AB060088E309014.png"
import "@assets/puzzle/1AB060088E309015.png"
import "@assets/puzzle/1AB060088E309017.png"
import "@assets/puzzle/D7353307267003E0.png"
import "@assets/puzzle/D7353307267003E1.png"
import "@assets/puzzle/D7353307267003E2.png"
import "@assets/puzzle/D7353307267003E3.png"
import "@assets/puzzle/D7353307267003E4.png"
import "@assets/puzzle/D7353307267003E5.png"
import "@assets/puzzle/D7353307267003E6.png"
import "@assets/puzzle/D7353307267003E7.png"
import "@assets/puzzle/D7353307267003EE.png"

// Constants

const CLASS = 'm-Puzzle'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class Puzzle extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Puzzle(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    let images = this.root.querySelectorAll('.m-PuzzleItem')
    let currentEl;
    let mouseStart;
    let tX;
    let tY;
    let savePos;
    let base = this.root;
    let rootRect = base.getBoundingClientRect;

    let dragStart = (image) => {
      rootRect = base.getBoundingClientRect();
      if(image.classList.contains('m-PuzzleItem--explination')) {
        base.querySelector('.m-PuzzleArrow').style.opacity = 0;
        base.querySelector('.m-PuzzleOutline').style.opacity = 0;
      }
      currentEl = image;
      savePos = {
        x: currentEl.getAttribute('x') * 1,
        y: currentEl.getAttribute('y') * 1
      }
    }

    let firsthover = false;
    let drag = (e) => {
      if(firsthover == false) {
        base.querySelector('.m-PuzzleArrow').style.opacity = 1;
        base.querySelector('.m-PuzzleOutline').style.opacity = 1;
        firsthover = true;
        puzzleReset(base.querySelectorAll('.m-PuzzleItem--normal')) //on first hover
      }
      if(currentEl) {
        tX = map(((e.clientX - rootRect.left) - (mouseStart.x - rootRect.left)), 0, rootRect.right - rootRect.left, 0, 2340);
        tY = map(((e.clientY - rootRect.top) - (mouseStart.y - rootRect.top)), 0, rootRect.bottom - rootRect.top, 0, 1500);
        let x = Math.floor(savePos.x + tX);
        let y = Math.floor(savePos.y + tY);
        currentEl.style.transform = `translate3d(${x}px,${y}px,0)`;
        //currentEl.setAttribute('transform', `matrix(1 0 0 1 ${x} ${y})` );
        currentEl.setAttribute('x', x );
        currentEl.setAttribute('y', y );
      } else {
        mouseStart = {x: e.clientX, y: e.clientY};
      }
    }
    
    let puzzleReset = (array) => {
      array.forEach((image) => {
        image.setAttribute('x', 0 );
        image.setAttribute('y', 0 );
      })

      const animationReset = anime({
        targets: array,
        style: 'transform: translate3d(0px, 0px, 0px)',
        delay: anime.stagger(20),
        duration: 400,
        easing: 'easeOutQuad',
      })
    }


    images.forEach((image) => {
      let randomX = map(Math.random(), 0, 1, -300,  300);
      let randomY = map(Math.random(), 0, 1, -300,  300);
      image.setAttribute('x', randomX );
      image.setAttribute('y', randomY );
      image.style.transform = `translate3d(${randomX}px,${randomY}px,0)`;

      if(image.classList.contains('m-PuzzleItem--explination')) {
        image.setAttribute('x', 500 );
        image.setAttribute('y', -100 );
        image.style.transform = `translate3d(500px,-100px,0)`;
      }

      image.addEventListener('mousedown', e => {
        dragStart(image);
      })
      image.addEventListener('touchstart', e => {
        dragStart(image);
      })
    })

    this.root.addEventListener('mousemove', e => {
      drag(e)
    })
    this.root.addEventListener('touchmove', e => {
      drag(e)
    })

    this.root.addEventListener('mouseup', e => {
      currentEl = undefined;
    })
    this.root.addEventListener('touchend', e => {
      currentEl = undefined;
    })



    this.root.querySelector('.m-PuzzleReset').addEventListener('click', () => {
      base.querySelector('.m-PuzzleArrow').style.opacity = 0;
      base.querySelector('.m-PuzzleOutline').style.opacity = 0;
      puzzleReset(images)
    })


  }
}
