import "./music.scss"
import anime from 'animejs/lib/anime.es.js'
import Component from '../../shared/component'

// Constants

const CLASS = 'm-Music'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class Music extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Music(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    //console.log('init Music')
    
    let base = this.root;

    let player = this.root.querySelector('.m-Music-player');
    let audio = this.root.querySelector('.m-Music-audio');

    const animation = anime({
      targets: this.root.querySelector('.m-Music-cover'),
      rotate: '360deg',
      duration: 6000,
      easing: 'linear',
      loop: true,
      autoplay: false
    })

    audio.onended = function() {
        player.classList.remove('is-playing')
        animation.pause();
    };

    player.addEventListener('click', () => {
      //play mp3 file
      if(!player.classList.contains('is-playing')) {
        player.classList.add('is-playing')
        animation.play();
        audio.play();
      } else {
        player.classList.remove('is-playing')
        animation.pause();
        audio.pause();
      }
    })
    
  }
}
