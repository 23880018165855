import barba from '@barba/core'
import anime from 'animejs/lib/anime.es.js'
import SmoothScroll from 'smooth-scroll'
import LazyLoad from 'lazyload'

var scroll = new SmoothScroll('a[href*="#"]');


import '@shared/global.scss'

//import mouse position and scroll position
//import mousePosition from '@shared/mouse'
//import { scrollY, mouseX, mouseY} from '@shared/listener'
import Scheduler from '@shared/scheduler'

import Article from '@modules/article'
import Header from '@modules/header'
import Footer from '@modules/footer'
import Intro from '@modules/intro'
import ImageRow from '@modules/image-row'
import ImageRowTop from '@modules/image-row-top'
//import Carousel from '@modules/carousel'
import Teaser from '@modules/teaser'
import Button from '@modules/button'
import Historie from '@modules/historie'
import Contact from '@modules/contact'
import SubpageIntro from '@modules/subpage-intro'
import Archive from '@modules/archive'
import Music from '@modules/music'
import Store from '@modules/store'
import Puzzle from '@modules/puzzle'
import Legal from '@modules/legal'
import { loopItems } from './shared/scheduler'

class App {
  constructor(root) {
    this.instances = []
    barba.init({
      schema: {
        prefix: 'data-context',
        container: 'page',
        wrapper: 'site'
      },
      prevent: ({ el }) => {
        return el.dataset.action
      },
      transitions: [{
        name: 'default',
        sync: false,
        leave(data) {
          const animation = anime({
            targets: data.current.container,
            opacity: [1, 0],
            duration: 400,
            easing: 'linear'
          })
          return animation.finished
        },
        once(data) {
          document.querySelector('body').classList = "is-page-" + data.next.namespace;
        },
        enter(data) {
          document.querySelector('body').classList = "is-page-" + data.next.namespace;
          const animation = anime({
            targets: data.next.container,
            opacity: [0, 1],
            duration: 400,
            easing: 'linear'
          })
          return animation.finished
        }
      }]
    })
    barba.hooks.afterEnter(() => {
      document.documentElement.classList.remove('is-navigating')

      let images = document.querySelectorAll(".lazyload");
      //new LazyLoad(images);
      new LazyLoad(images, {
        root: null,
        rootMargin: "500px",
        threshold: 0
      });
    })
    barba.hooks.beforeLeave(() => {
      document.documentElement.classList.add('is-navigating')
    })
    barba.hooks.beforeEnter((data) => {
      window.scrollTo(0, 0)
      loopItems.length = 0;
      this.upgradeAll(data.next.container)
    })
    barba.hooks.afterLeave((data) => {
      //this.destroy(data.current)
    })
    this.upgradeAll(root)
  }

  go(url) {
    barba.go(url)
  }

  /*destroy(container) {
    this.instances.forEach((instance) => {
      instance.destroy()
    })
    this.instances = []
  }*/

  upgradeAll(container) {
    this.instances.push(Historie.upgradeAll(container))
    //this.instances.push(Carousel.upgradeAll(container))
    this.instances.push(Intro.upgradeAll(container))
    this.instances.push(ImageRow.upgradeAll(container))
    this.instances.push(Article.upgradeAll(container))
    this.instances.push(Archive.upgradeAll(container))
    this.instances.push(Music.upgradeAll(container))
    this.instances.push(Teaser.upgradeAll(container))
    this.instances.push(Puzzle.upgradeAll(container))
    /*console.log('loopItems:')
    console.log(loopItems)*/
  }
}

window.app = new App(document.querySelector(`[data-context="site"]`))


