// export function clamp(val: number, min = 0, max = 1): number {
//   return Math.min(Math.max(val, Math.min(min, max)), Math.max(min, max));
// }

// export function normalize(
//   val: number,
//   min: number,
//   max: number,
//   _clamp = false
// ): number {
//   if (_clamp) return clamp((val - min) / (max - min));
//   return (val - min) / (max - min);
// }

// export function lerp(a: number, b: number, t: number): number {
//   return a + (b - a) * clamp(t);
// }

// export function fract(value: number): number {
//   return value - Math.floor(value);
// }

// export function wrap(n: number, max: number): number {
//   return fract(n / max) * max;
// }


export function map(n, start1, stop1, start2 = 0, stop2 = 1) {
  return ((n-start1)/(stop1-start1))*(stop2-start2)+start2;
};
  
export function clamp(number, min = 0, max = 1) {
  return Math.max(min, Math.min(number, max));
}


export const spanFerkel = (s) => {
	if ( s ) {
		return `<div><mark>${
			s.split( '' ).map(
				c => ( c.toLowerCase() !== c ? `<span>${c}</span>` : `<span>${c}</span>` ),
			).join( '' )
				.split( ' ' )
				.join( '</mark> <mark>' )
		}</span></div>`;
	}
	return '';
};
