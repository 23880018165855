import "./teaser.scss"

import Component from '../../shared/component'
import p5 from "p5"

import "@assets/teaser-bg-01.jpg"

// Constants

const CLASS = 'm-Teaser'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class Teaser extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Teaser(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    //console.log('init Teaser')

    let base = this.root;
    let parentWidth = base.offsetWidth;
    let parentHeight = base.offsetHeight;

    //console.log(this.root)

    let sketch = function(p) {
        let counter = 0;
      
        p.setup = function() {
            p.createCanvas(parentWidth, parentHeight);
            p.fill(255,255,255);
            p.rect(0,0,p.width,p.height);
        };
      
        p.draw = function() {
            p.noFill();
            p.erase();
            
            if(p.mouseX != p.pmouseX || p.mouseY != p.pmouseY) {
                for(let i = 0; i < 15; i++) {
                    p.noFill();
                    p.stroke(0);
                    p.strokeWeight(p.random(3,20));
                    p.line(p.mouseX + p.random(-40, 40), p.mouseY + p.random(-40, 40), p.pmouseX + p.random(-40, 40), p.pmouseY + p.random(-40, 40));
                }
                p.strokeWeight(0);
                p.fill(0);
                p.circle(p.mouseX + p.random(-80, 80), p.mouseY + p.random(-80, 80), p.random(1,8));
                p.noStroke();
                p.noErase();
                p.fill(255,255,255,5);
                p.rect(0,0,p.width,p.height);
                counter = 0;
            }  else {
                p.noStroke();
                p.noErase();
                p.fill(255,255,255,20);
                if(counter > 100) {
                    counter = 0;
                    p.fill(255,255,255);
                }
                p.rect(0,0,p.width,p.height);
                counter++;
            }
                
        };

        p.windowResized = function() {
            parentWidth = base.offsetWidth;
            parentHeight = base.offsetHeight;
            p.resizeCanvas(parentWidth,parentHeight);
        }
    };
    
    let myp5 = new p5(sketch, base);
  }
}
