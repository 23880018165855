import "./image-row.scss"
import {loopItems, Item} from "@shared/scheduler"
import {windowHeight, scrollPosition} from "@shared/listener"
import Component from '../../shared/component'
import { clamp, map } from "@shared/math";


// Constants

const CLASS = 'm-ImageRow'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class ImageRow extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new ImageRow(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    //console.log('init ImageRow')
    
    let loopItem = new Item('ImageRow schedule')

    let base = this.root;
    let images = this.root.querySelectorAll('.m-ImageRow-image');

    let elTop = base.offsetTop;
    let elHeight = base.offsetHeight;

    loopItem.loop = function() {
      let scrollY = clamp(map(scrollPosition.rawY, elTop - windowHeight, elTop + elHeight, 0, 1))
      let zoom = map(scrollY, 0, 1, 0, 1);
        
      images.forEach(img => {
          img.style.transform = `scale(1.25) translate3d(-${zoom * 12.5}%, 0, 0)`;
      })
    }

    loopItems.push(loopItem);
  }
}
