import { EulerSpring } from "@downpourdigital/physics"
import { loop, postRender } from "@downpourdigital/scheduler"

export let windowHeight = window.innerHeight;
export let windowWidth = window.innerWidth;
window.addEventListener("resize", () => {
  windowHeight = window.innerHeight;
  windowWidth = window.innerWidth;
});

export const mousePosition = {
  x: 0,
  y: 0,
  rawX: 0,
  rawY: 0,
}

export const scrollPosition = {
    y: 0,
    rawY: 0,
    previousY: 0,
  }

const springMouseX = new EulerSpring({
  mass: 1,
  stiffness: 500,
  damping: 100,
})

const springMouseY = new EulerSpring({
  mass: 1,
  stiffness: 500,
  damping: 100,
})


const springScrollY = new EulerSpring({
    mass: 1,
    stiffness: 500,
    damping: 100,
})


let pscrollY = 0;
window.addEventListener('scroll', function() {
    pscrollY = scrollY;
    scrollY = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;

    springScrollY.set(scrollY)
    scrollPosition.rawY = scrollY;
    scrollPosition.previousY = pscrollY;
});


const supportsTouchEvents =
  "ontouchstart" in window ||
  (window.DocumentTouch && document instanceof window.DocumentTouch) ||
  navigator.maxTouchPoints > 0 ||
  window.navigator.msMaxTouchPoints > 0

if (supportsTouchEvents) {
  document.addEventListener("touchmove", e => {
    const touch = e.touches[0]
    springMouseX.set(touch.pageX)
    springMouseY.set(touch.pageY)
    mousePosition.rawX = touch.pageX
    mousePosition.rawY = touch.pageY
  })
} else {
  document.addEventListener("mousemove", e => {
    springMouseX.set(e.clientX)
    springMouseY.set(e.clientY)
    mousePosition.rawX = e.clientX
    mousePosition.rawY = e.clientY
  })
}

loop(() => [
  postRender(() => {
    const [x, y] = [springMouseX.get(), springMouseY.get()]
    mousePosition.x = x
    mousePosition.y = y

    const scrolly = springScrollY.get();
    scrollPosition.y = scrolly
  }),
])