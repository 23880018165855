import "./article.scss"

import {loopItems, Item} from "@shared/scheduler"
import {mousePosition, scrollPosition} from "@shared/listener"
import Component from '../../shared/component'
import { clamp, map } from "@shared/math";
import { windowHeight } from "@shared/listener";

// Constants

const CLASS = 'm-Article'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class Article extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Article(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    //console.log('init Article')
    
    let loopItem = new Item('Article schedule')

    let base = this.root;

    if(this.root.querySelector('.m-Article-image')) {
        let image = this.root.querySelector('.m-Article-image img');
    
        //console.log(image)
    
        let elTop = image.offsetTop;
        let elHeight = image.offsetHeight;
    
    
        
        let debug = document.querySelector('.debug');
        
        loopItem.loop = function() {
            let scrollY = clamp(map(scrollPosition.rawY, elTop - windowHeight, elTop + elHeight, 0, 1))
            let zoom = map(scrollY, 0, 1, 1, 1.2);
            
            //debug.innerHTML = zoom;
            image.style.transform = `scale(${zoom})`;
        }
    
        loopItems.push(loopItem);
    }
  }
}
