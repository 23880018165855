import "./intro.scss"
import {loopItems, Item} from "@shared/scheduler"
import {mousePosition, scrollPosition} from "@shared/listener"
import Component from '../../shared/component'
import { clamp, map} from "@shared/math";
import { windowHeight } from "@shared/listener";
import anime from 'animejs/lib/anime.es.js'
import { spanFerkel } from "../../shared/math";
//import { border } from "polished";

// Constants

const CLASS = 'm-Intro'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class Intro extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Intro(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    //console.log('init intro')
    
    let headline = this.root.querySelector('h1')
    let top = this.root.querySelector('.m-Intro-border--top')
    let bottom = this.root.querySelector('.m-Intro-border--bottom')
    let left = this.root.querySelector('.m-Intro-border--left')
    let right = this.root.querySelector('.m-Intro-border--right')
    let video = this.root.querySelector('video')

    video.play();
    let videoPlay = true;

    headline.innerHTML = spanFerkel(headline.innerHTML);

    const animation = anime({
      targets: headline.querySelectorAll('span'),
      opacity: [0, 1],
      translateY: [20, 0],
      duration: 400,
      easing: 'linear',
      delay: anime.stagger(200, {easing: 'easeOutQuad'})
    })

    let loopItem = new Item('intro schedule')
    
    let base = this.root;
    let header = document.querySelector('.m-Header'); 
    let debug = document.querySelector('.debug'); 
    loopItem.loop = function() {
        let scrollY = scrollPosition.rawY;
        scrollY = clamp(scrollY, 0, windowHeight + 200)
        
        let headerScroll = clamp(map(scrollY, 200, windowHeight + 200, 0, 1), 0, 1)
      
        //debug.innerHTML = headerScroll;
        let borderAnimation = clamp(map(scrollY, 0, 200, -100, 0), -100, 0);
        let zoom = clamp(map(scrollY, 0, 200, 1.1, 1.2), 1.1, 1.2);
        let y = clamp(map(scrollY, 200, windowHeight + 200, 0, windowHeight), 0, windowHeight);
        
        header.style.transform = `translate3d(0, ${100 - (headerScroll * 100)}vh, 0)`;
        base.style.transform = `translate3d(0, -${y}px, 0)`;
        headline.style.transform = `translate3d(0, ${y * 0.5}px, 0)`;
        left.style.transform = `translate3d(${borderAnimation}%, 0, 0)`;
        right.style.transform = `translate3d(${borderAnimation * (-1)}%, 0, 0)`;
        top.style.transform = `translate3d(0,${borderAnimation}%, 0)`;
        bottom.style.transform = `translate3d(0,${borderAnimation * (-1)}%, 0)`;
        video.style.transform = `translate3d(-50%, -50%, 0) scale(${zoom})`;

        if(headerScroll >= 1 && videoPlay == true) {
          video.pause();
          videoPlay = false;
          video.style.opacity = "0";
        } else if(headerScroll < 1 && videoPlay == false) {
          video.play();
          video.style.opacity = "1";
          videoPlay = true;
        }
    }

    loopItems.push(loopItem);
  }
}
