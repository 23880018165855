import "./historie.scss"
import Component from '../../shared/component'
import "@assets/icon-plus.png"

// Constants

const CLASS = 'm-Historie'

export const ClassName = {
  ROOT: CLASS
}
export const Selector = {
  ROOT: `.${ClassName.ROOT}`
}

// Class Definition

export default class Historie extends Component {
  static upgradeAll() {
    const components = []
    Array.from(document.querySelectorAll(Selector.ROOT)).forEach(element => {
      if (element.instance == null) element.instance = new Historie(element)
      components.push(element.instance)
    })
    return components
  }

  init() {
    //console.log('init historie')
    let expandables = this.root.querySelectorAll('.m-Historie-list .m-Historie-element--expandable');
    
    expandables.forEach( expandable => {
        expandable.addEventListener('click', e => {
            if(expandable.classList.contains('active')) {
                this.collapse(expandable)
            } else {
                expandables.forEach( item => {
                    this.collapse(item)
                })
                expandable.classList.add('active');
                let height = expandable.querySelector('.m-Historie-expandable-wrapper').offsetHeight;
                //height = height + expandable.offsetHeight;
                expandable.querySelector('.m-Historie-expandable').style.maxHeight = height + 'px';
            }
        })
    })
    
    this.collapse = (el) => {
        el.classList.remove('active');
        el.querySelector('.m-Historie-expandable').style.maxHeight = '0px';
    }
  }
}
